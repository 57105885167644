$primary: #226cfc;
$body-color: #333;
$gray-800: #343a40;
$light-gray-1: #fafafa;
$light-gray-2: #f8f8f8;
$light-gray-3: #f5f5f5;
$light-gray-4: #f0f0f0;
$light-gray-5: #e8e8e8;
$theme-colors: (
  "primary": $primary,
);

$font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;
$input-btn-focus-box-shadow: none;
$custom-select-bg-size: 16px 12px;
$custom-select-indicator-color: $gray-800;
$custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$custom-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
