.color-success {
  color: #009688;
  height: 20px;
}
.color-error {
  color: #f44336;
  height: 20px;
}
.toast-cont {
  display: flex;
  align-items: center;
}
