table {
  border-spacing: 0;
  border: 1px solid black;
  border-radius: 10px;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    min-width: 120px;
    :last-child {
      border-right: 0;
    }
  }
}

.pagination {
  padding: 0.5rem;
  display: flex;
  justify-content: end;
}
.table-btn {
  margin: 0 2px;
  border: none;
  color: #fff;
  background-color: #226cfc;
  border-color: #226cfc;
  border-radius: 4px;
  padding: 3px 10px;
  &:disabled {
    background-color: rgb(171, 166, 166);
  }
}
