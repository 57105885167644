:root {
  --scroll-bg: #00000020;
}

// scrollbar
* {
  scrollbar-color: var(--scroll-bg) #ffffff00;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  @include max-width(576px) {
    width: 3px;
    height: 3px;
  }
}
::-webkit-scrollbar-thumb {
  background: var(--scroll-bg);
  @include min-width(576px) {
    border: 2px white solid;
    background-clip: padding-box;
    border-radius: 0.25rem;
  }
}

body {
  margin: 0;
  font-family: "Ubuntu", $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
textarea {
  resize: none;
}

.app-container {
  display: flex;
  height: 100vh;
  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0 2px;
    font-weight: 500;
    width: 230px;
    flex-shrink: 0;
    border-right: 1px solid #00000025;

    .nav {
      overflow-y: auto;
      flex-wrap: nowrap;
      padding-bottom: 1rem;
      .nav-link {
        display: flex;
        align-items: center;
        color: $body-color;
        border-radius: 0;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        margin-bottom: 0.5rem;
        border-left: 4px solid transparent;
        transition: all 0.1s ease-in-out;
        &:not(.active):hover {
          background-color: transparentize($primary, $amount: 0.9);
        }
        &.active {
          border-color: $primary;
          background-color: white;
          color: $primary;
        }
        img {
          height: 1.2rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
  .tab-content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    height: 100%;
    width: 84%;
  }
}

.shows-grid {
  .card-img-top {
    height: 300px;
  }
}

.favorite-active {
  transform: scale(1.2);
}
.loader,
.no-records {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo {
  img {
    height: 2rem;
  }
}
.user {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 3rem;
    width: 3rem;
    overflow: hidden;
    border-radius: 50%;
  }
}
.letter-space {
  letter-spacing: 1.5px;
}

.btn {
  &-light {
    color: $primary;
    background-color: transparentize($primary, $amount: 0.9);
    &:hover {
      color: $primary;
    }
  }
  &-100 {
    min-width: 100px;
  }
  &-more {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0.25rem;
    img {
      height: 1.5rem;
    }
  }
}

.btn-filter {
  img {
    height: 1.5rem;
  }
}
.form-control {
  text-overflow: ellipsis;
}
.search {
  position: relative;
  .form-control {
    padding-right: 2rem;
    border: none;
    box-shadow: 0px 1px 5px #00000030;
  }
  .form-control-icon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    height: 1rem;
  }
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
}
.header {
  padding: 1rem;
}
.content-scroll {
  padding: 1rem;
  overflow: auto;
  min-height: 600px;
}
.footer {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h-1 {
  height: 1rem;
}
.custom-form {
  .form-control {
    border-top-left-radius: 0;
  }
}
.form-label {
  padding: 0.1rem 0.5rem;
  color: white;
  background: $primary;
  margin-bottom: 0px;
  border-radius: 0.5rem 0.5rem 0.5rem 0;
  font-size: 0.7rem;
}

.card {
  border-radius: 2rem;
  box-shadow: 5px 1px 5px 1px #00000015;
}
.custom-group {
  .input-group-text {
    background-color: $primary;
    color: white;
    border-color: $primary;
    margin-right: -2px;
    z-index: 9;
    border-radius: 0.25rem !important;
  }
  img {
    height: 1.1rem;
    filter: invert(1);
  }
}

.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: #00000025;
  z-index: 99999;
  backdrop-filter: blur(30px);
  &-box {
    display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    width: 100%;
    min-width: 250px;
    min-height: 250px;
    max-height: calc(100% - 1rem);
    border-radius: 2rem;
    animation: scaleup 0.15s ease-in-out forwards;
    will-change: transform, opacity;
    &.sm {
      max-width: 400px;
    }
    &.md {
      max-width: 600px;
    }
    &.lg {
      max-width: 800px;
    }
    &.xl {
      max-width: 1000px;
    }
    &.xxl {
      max-width: 1200px;
    }
    .card {
      box-shadow: none;
      border: none;
    }
  }
  .popup-body {
    padding: 1rem;
    flex: 1;
    // overflow: auto !important;
    overflow: visible;
  }
  .btn-popup-close {
    img {
      height: 1rem;
    }
  }
}
.blog-img {
  min-height: 70px;
  width: 150px;
  border: 1px solid #00000030;
}
.custom-shadow {
  box-shadow: 1px 1px 4px 1px #00000030;
}
.tabs {
  z-index: 5;
  .nav-item.nav-link {
    border-radius: 0;
    border-bottom: 2px solid transparent;
    &.active {
      color: inherit;
      background-color: inherit;
      border-bottom-color: $primary;
    }
  }
}

.small-input {
  height: 30px !important;
}

//----------------Toast default changes--------------
html body .Toastify__toast-container {
  width: unset;
  min-width: 300px;
  max-width: 500px;
  font-weight: 600;
  letter-spacing: 0.1px;
  font-size: 1rem;
  z-index: 9999999;
  .Toastify__close-button {
    width: 40px;
    margin-top: 0.6rem;
    outline: none;
  }
}
.Toastify__toast--success {
  background-color: white !important;
  color: #009688;
  font-size: 1rem;
}
.Toastify__toast--error {
  background-color: white !important;
  color: #f44336;
  font-size: 1rem;
}

.Toastify__close-button {
  color: #7c7c7c !important;
}

.Toastify__progress-bar {
  color: #7c7c7c !important;
}

.Toastify__toast--info {
  visibility: hidden !important;
  opacity: 0;
  display: none !important;
  width: 0 !important;
}
.img-logo {
  width: 150px;
  max-height: 100px;
}

.dropdown-menu.show {
  height: 300px !important;
  overflow: auto;
}
.dropdown-item {
  padding: 5px !important;
}

.error-border {
  border: 1px solid red;
}
.error-text {
  color: red;
  font-size: 14px;
}

.audit-popup {
  overflow: visible !important;
}
.whitelist {
  background-color: #9acd32 !important;
}
.not-whitelist {
  background-color: #c81634 !important;
}
